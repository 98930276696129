import { Nullable } from '@/core/domain/type/types'

export class TaskDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    status?: Nullable<string>;
    error_message?: Nullable<string>;
    project_id?: Nullable<number>;
    task_id?: Nullable<string>;
    command_name?: Nullable<string>;
    command_payload?: any;
    start_time?: Nullable<string>;
    end_time?: Nullable<string>;

    constructor({ id, guid, status, error_message, project_id, task_id, command_name, command_payload, start_time, end_time } : { id?: Nullable<number>, guid?: Nullable<string>, status?: Nullable<string>, error_message?: Nullable<string>, project_id?: Nullable<number>, task_id?: Nullable<string>, command_name?: Nullable<string>, command_payload?: any, start_time?: Nullable<string>, end_time?: Nullable<string>}) {
        this.id = id;
        this.guid = guid;
        this.status = status;
        this.error_message = error_message;
        this.project_id = project_id;
        this.task_id = task_id;
        this.command_name = command_name;
        this.command_payload = command_payload;
        this.start_time = start_time;
        this.end_time = end_time;
    }
}

